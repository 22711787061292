import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'gatsby-theme-psg/src/components/Layout'
import Header from '../components/Header'
import Lead from '../components/Lead'
import Context from 'gatsby-theme-psg/src/components/Events/Context';
import Img from 'gatsby-image'

export const TimetablePageTemplate = ({ title, content }) => {
  return (
      <React.Fragment>
        <Header>
          <Lead title={title} content={content}/>
        </Header>
        <div className={"content"}>
          <div className="container my-5 py-5">
            <Context.Consumer>
              {({ state }) => (
                  state.event.frontmatter.timetable ?
                      <Img fluid={state.event.frontmatter.timetable.childImageSharp.fluid}
                           alt="timetable"/>
                      :
                      <div className="lead text-center">De timetable komt binnenkort online</div>
              )}
            </Context.Consumer>
          </div>
        </div>
      </React.Fragment>
  )
}

const TimetablePage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
      <Layout title={post.frontmatter.title} description={post.frontmatter.description}>
        <TimetablePageTemplate title={post.frontmatter.title} content={post.html} />
      </Layout>
  )
}

export default TimetablePage

export const pageQuery = graphql`
  query TimetablePage {
    markdownRemark(frontmatter: { templateKey: { eq: "timetable-page" } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
